import { Injectable , Inject } from '@angular/core';
import { HttpProductClientService } from 'src/app/http-client-product.service'; 
import { HttpClientService } from 'src/app/http-client.service';
import {HttpClientInformationService} from 'src/app/http-client-information.service';
import {Router} from '@angular/router';
import {Observable} from 'rxjs/Observable'; 
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { PLATFORM_ID } from '@angular/core';
 
 
@Injectable({
  providedIn: 'root'
}) 
export class ProductService {
  constructor(private http: HttpProductClientService, 
      private http1:HttpClientInformationService,
      private http2:HttpClientService,
      private router: Router,
     @Inject(PLATFORM_ID) private platformId: Object) { }
 
  getProducts(page, page_size , name, category, status, created_at, published_at, delivery_type): Observable<any> {
    return this.http.get(`products?page=${page}&page_size=${page_size}&name=${name}&category=${category}&status=${status}&created_at=${created_at}&published_at=${published_at}&delivery_type=${delivery_type}`).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  getProductById(id): Observable<any> {
    return this.http.get(`products/${id}`).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  getProductCategories(): Observable<any> {
    return this.http.get(`products/categories/get`).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }


  createProduct(data): Observable<any> {
    return this.http.post('products/add', data).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  getProductCategory(data): Observable<any> {
    return this.http.post('products/getCategory', data).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }
  
  updateProduct(data): Observable<any> {
    return this.http.post('products/add', data).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  deleteProduct(id): Observable<any> {
    return this.http.delete('products/delete/'+id).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  
  getProductMappingById(id): Observable<any> {
    return this.http.get(`products/mapping/single/${id}`).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  getProductMapping(product_id): Observable<any> {
    return this.http.get(`products/mapping/get/${product_id}`).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  getProductMappingList(type): Observable<any> {
    return this.http.get(`products/mapping/list/${type}`).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

// Product Object Mapping
  addProductMapping(data): Observable<any> {
    return this.http.post('products/mapping/add', data).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }
  
  updateProductMapping(data): Observable<any> {
    return this.http.post('products/mapping/add', data).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  deleteProductMapping(id): Observable<any> {
    return this.http.delete('products/mapping/delete/'+id).map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }
  

  getTestList(): Observable<any> {
    return this.http1.get('test-master/get-test-list').map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  getStudyLocationList(): Observable<any> {
    return this.http2.getWithAuthKey('study-locations/get-study-locations-list').map((res: Response) => {
      return res;
    }).catch((error: any) => Observable.throw(error.error));
  }

  universityList(form,school_name_search,city_name_search,country_name_search,is_active_search,pageLimit,pageNo) { 
    return this.http2.getWithAuthKey('schools/schools-list?school_name_search='+school_name_search+'&city_name_search='+city_name_search +'&country_name_search='+country_name_search+'&is_active_search='+is_active_search+'&page_limit='+pageLimit + '&page_no='+pageNo).map((res: Response) => {  
    return res;
  })
  .catch((error: any) => Observable.throw(error.error)); 
}

getAllNews(page,page_size, press_name): Observable<any> {
  return this.http1.get('common/getAllNews?page=' + page + '&page_size=' + page_size + '&press_name=' + press_name ).map((res: Response) => {
    return res;
  }).catch((error: any) => Observable.throw(error.error));
}

delNewsById(press_release_id): Observable<any> {
  return this.http1.delete('common/delNewsById/' +press_release_id).map((res: Response) => {
    return res;
  }).catch((error: any) => Observable.throw(error.error));
}

getNewsById(press_release_id): Observable<any> {
  return this.http1.get('common/getNewsById/' +press_release_id).map((res: Response) => {
    return res;
  }).catch((error: any) => Observable.throw(error.error));
}

addNews(data): Observable<any> {
  return this.http1.post('common/addNews', data).map((res: Response) => {
    return res;
  }).catch((error: any) => Observable.throw(error.error));
}
searchSchool(search) {
  return this.http1.get('schools/searchSchools?search=' + search ).map((res: Response) => {
    return res;
  })
    .catch((error: any) => Observable.throw(error.error));
}
}
